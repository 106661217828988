<template>
  <div class="g-box g-role-list-box" v-loading="loading">
    <div class="g-export-loading" v-if="exportLoading">
      <div class="g-export-loading-process">
        <i class="el-icon-loading"></i>
        &nbsp;
        <span v-if="exportFetching">{{
          exportLoadingPartIndex == 1
            ? "正在导出"
            : "正在导出 ( " +
              exportLoadingPartIndex +
              " / " +
              exportLoadingPartTotal +
              " )"
        }}</span>
        <span v-else>合成中...</span>
      </div>
    </div>
    <slot-top
      v-if="$options.components.slotTop"
      :slot-top-data="slotTopData"
      ref="slotTop"
    >
    </slot-top>
    <div class="m-form-inline" data-m="select">
      <div class="m-form-inline-mn">
        <table-filter
          :show-list="showList"
          :hide-list="hideList"
          v-model="formInline"
          @filterChange="toPage(1)"
        ></table-filter>
      </div>
    </div>
    <div class="m-list" data-m="list">
      <m-table-icons
        @sizeChange="(val) => (tableSize = val)"
        :columns="tableTileTemp"
        @columnChange="ddd"
      >
        <div slot="btns" style="width: 100%">
          <div v-if="isDiyBtns == 'empty'"></div>
          <btns v-else-if="$options.components.Btns" ref="Btns">
            <el-popover
              v-if="(!tableAction.view || tableAction.view.show) && showExport"
              placement="right-start"
              trigger="hover"
            >
              <download-excel
                v-if="
                  (!tableAction.view || tableAction.view.show) && showExport
                "
                class="export-excel-wrapper"
                :before-finish="beforeImportOk"
                :before-generate="beforeImport"
                :data="json_data"
                :fields="json_fields"
                :name="json_fileName"
              >
                <span class="export-btn1">导出所有数据</span>
              </download-excel>

              <download-excel
                v-if="
                  (!tableAction.view || tableAction.view.show) && showExport
                "
                class="export-excel-wrapper"
                :before-generate="beforeImportQuery"
                :data="json_data"
                :fields="json_fields"
                :name="json_fileName"
              >
                <span class="export-btn1">导出查询条件</span>
              </download-excel>

              <el-button
                slot="reference"
                class="u-add btns-box-gutter"
                size="mini"
                type="success"
              >
                {{ $t("global.export") }}
              </el-button>
            </el-popover>
          </btns>
          <div v-else>
            <div class="my-btns">
              <el-button
                style="width: 80px"
                type="primary"
                size="small"
                @click="add"
                v-if="!tableAction.add || tableAction.add.show"
              >
                {{ $t("global.add") }}
              </el-button>
              <el-button
                style="width: 80px"
                type="primary"
                size="small"
                @click="importClick"
                v-if="!tableAction.import || tableAction.import.show"
              >
                {{ $t("global.import") }}
              </el-button>

              <el-popover
                v-if="
                  (!tableAction.view || tableAction.view.show) && showExport
                "
                placement="right-start"
                trigger="hover"
              >
                <download-excel
                  v-if="
                    (!tableAction.view || tableAction.view.show) && showExport
                  "
                  class="export-excel-wrapper"
                  :before-generate="beforeImport"
                  :before-finish="beforeImportOk"
                  :data="json_data"
                  :fields="json_fields"
                  :name="json_fileName"
                >
                  <span class="export-btn1">导出所有数据</span>
                </download-excel>

                <download-excel
                  v-if="
                    (!tableAction.view || tableAction.view.show) && showExport
                  "
                  class="export-excel-wrapper"
                  :before-generate="beforeImportQuery"
                  :data="json_data"
                  :fields="json_fields"
                  :name="json_fileName"
                >
                  <span class="export-btn1">导出查询条件</span>
                </download-excel>

                <el-button
                  slot="reference"
                  size="small"
                  style="width: 80px"
                  type="success"
                >
                  {{ $t("global.export") }}
                </el-button>
              </el-popover>
            </div>
          </div>
        </div>
      </m-table-icons>
      <el-table
        :data="tableData"
        :size="tableSize"
        tooltip-effect="dark"
        ref="multipleTable"
      >
        <el-table-column
          align="center"
          v-for="item in tableTile"
          :label="$t(item.columnLabel)"
          :prop="item.prop"
          :type="item.type ? item.type : ''"
          :key="item.key"
          :width="item.width"
          :selectable="item.selectable ? item.selectable : null"
          :formatter="item.formatter ? item.formatter : null"
          :fixed="item.fixed ? item.fixed : null"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          fixed="right"
          :label="$t(`global.操作`)"
          :width="handleTableColumnWidth"
          v-if="tableAction.show"
        >
          <template slot-scope="scope">
            <tableAction
              :scope="scope"
              v-if="$options.components.TableAction"
              ref="Btns"
            ></tableAction>
            <div v-else>
              <el-button
                circle
                icon="iconfont icon-xiangqing primary-color"
                :title="tableAction.view ? $t(tableAction.view.title) : '详情'"
                size="mini"
                @click="view(scope.row.id)"
                v-if="!tableAction.view || tableAction.view.show"
              ></el-button>
              <!--            tool.checkButton('role-info')-->
              <el-button
                circle
                :icon="
                  tableAction.edit && tableAction.edit.icon
                    ? tableAction.edit.icon
                    : 'iconfont icon-bianji primary-color'
                "
                :title="tableAction.edit ? $t(tableAction.edit.title) : '编辑'"
                size="mini"
                @click="edit(scope.row.id)"
                v-if="
                  !tableAction.edit ||
                  (tableAction.edit.show && !tableAction.edit.condition) ||
                  (tableAction.edit.show && !tableAction.edit.condition) ||
                  (tableAction.edit.show &&
                    checkCondition(scope.row, tableAction.edit.condition))
                "
              ></el-button>
              <el-button
                circle
                icon="iconfont icon-shanchu danger-color"
                :title="tableAction.remove ? $t(tableAction.remove.title) : '删除'"
                size="mini"
                @click="remove(scope.row.id)"
                v-if="
                  !tableAction.remove ||
                  (tableAction.remove.show && !tableAction.remove.condition) ||
                  (tableAction.remove.show && !tableAction.remove.condition) ||
                  (tableAction.remove.show &&
                    checkCondition(scope.row, tableAction.remove.condition))
                "
              ></el-button>
              <template v-if="!tableAction.valid || tableAction.valid.show">
                <el-button
                  circle
                  icon="iconfont icon-qiyong warn-color"
                  :title="$t('global.enable')"
                  v-if="scope.row.status === 1"
                  size="mini"
                  @click="disable(scope.row.id)"
                ></el-button>
                <el-button
                  circle
                  icon="iconfont icon-tingyongzice warn-color"
                  :title="$t('global.valid')"
                  v-else-if="scope.row.status === 0"
                  size="mini"
                  @click="enable(scope.row.id)"
                ></el-button>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      class="m-page"
      :class="tableData.length <= 10 ? 'm-page2' : ''"
      data-m="list"
      v-if="formInline.page.total > 0"
    >
      <p class="u-p">
        <!--        总共{{ formInline.page.total }}条数据-->
      </p>
      <el-pagination
        background
        layout="prev, pager, next, sizes, jumper"
        :total="formInline.page.total"
        :page-size.sync="formInline.page.size"
        :current-page="formInline.page.currentPage"
        @current-change="toPage"
        :page-sizes="[10, 20, 30, 40]"
        @size-change="toPage(1)"
      ></el-pagination>
    </div>

    <my-dialog v-if="$options.components.MyDialog" ref="myDialog"> </my-dialog>

    <my-dialog2 v-if="$options.components.MyDialog2" ref="myDialog2">
    </my-dialog2>

    <my-dialog3 v-if="$options.components.MyDialog3" ref="myDialog3">
    </my-dialog3>
  </div>
</template>

<script>
import Vue from 'vue'
import api from '../api'
import tableFilter from '../components/table-filter'
import mTableIcons from '../components/m-table-icons'
import downloadExcel from 'vue-json-excel'
import { treearr } from '../tool'

export default Vue.extend({
  name: 'base-list',
  components: { tableFilter, mTableIcons, downloadExcel },
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      isOKBtn: false,
      handleTableColumnWidth: 180,
      tableSize: 'medium',
      tableTileTemp: [],
      isDiyBtns: false,
      isDiyDialog: false,
      isDiyDialog2: false,
      isDiyDialog3: false,
      tableAction: {
        // 操作栏显示的按钮
        show: true
      },
      getOrgData: false,
      showExport: true,
      showList: [
        {
          copName: 'el-input',
          placeholder: '关键字',
          key: 'name',
          name: '',
          attrs: ['placeholder']
        }
      ],
      hideList: [],
      formInline: {
        name: '',
        page: {
          total: 0,
          size: 10,
          currentPage: 1
        }
      },
      filter: {},
      tableData: [],
      tableTile: [
        {
          key: '0',
          columnLabel: '序号',
          width: '80',
          type: 'index'
        },
        {
          key: '1',
          columnLabel: '字段a',
          prop: 'name',
          width: ''
        },
        {
          key: '2',
          columnLabel: '字段b',
          prop: 'tips',
          width: ''
        },
        {
          key: '3',
          columnLabel: '创建人',
          prop: 'founder',
          width: ''
        },
        {
          key: '4',
          columnLabel: '创建时间',
          prop: 'creationTime',
          width: ''
        }
      ],
      orgOptions: [], // 组织树
      loading: true,
      json_fileName: '导出数据.xls',
      json_fields: {},
      json_data: [],
      extraQuery: {},
      exportLoading: false,
      exportLoadingPartIndex: 1,
      exportLoadingPartTotal: 10,
      exportFetching: false,
      slotTopData: {}
    }
  },
  watch: {

  },
  created () {
    this.init()
    // this.tableTileTemp = JSON.parse(JSON.stringify(this.tableTile))

    this.tableTileTemp = [...this.tableTile]

    var json = {

    }

    this.tableTileTemp.forEach(ele => {
      if (ele.prop) {
        json[ele.columnLabel] = ele.prop
      }
    })

    this.json_fields = json
    // 组织
    if (this.getOrgData) {
      api.auth.getLevelAllOrg().then(res => {
        // this.filter.orgOptions = this.tree(res.data)
        var sss = treearr(res.data)
        this.orgOptions = sss
      })
    }
  },
  methods: {
    async beforeImport () {
      this.exportLoading = true

      var cur = 1
      var max = 5
      this.exportLoadingPartIndex = cur
      this.exportLoadingPartTotal = max

      var dataBlock = [

      ]

      var extraQuery = this.extraQuery
      var that = this
      var patchAjax = async function () {
        if (cur <= max) {
          that.exportLoadingPartIndex = cur
          that.exportLoadingPartTotal = max
          var query = {
            page: cur,
            size: 200,
            ...extraQuery
          }
          return await that.query(query).then((res) => {
            var list = res.data.records
            max = res.data.pages
            dataBlock = [...dataBlock, ...list]
            cur++
            return patchAjax()
          })
        } else {
          console.log('dataBlock', dataBlock)
          return dataBlock
        }
      }
      this.exportFetching = true
      await patchAjax().then(res => {
        this.exportFetching = false
        var list = res.map((item) => {
          var itemAuditStatusMap = {
            0: '未上报',
            1: '待审核',
            2: '审核通过',
            3: '已退回'
          }
          return {
            ...item,
            id: item.id || '',
            status: item.status ? '是' : '否',
            auditStatus: itemAuditStatusMap[item.auditStatus],
            createTime: this.$moment(item.createTime).format('YYYY-MM-DD HH:mm:ss') || ''
          }
        })
        console.log('list', list.length)
        this.json_data = list

        if (list.length === 0) {
          this.$message.warning('无数据可导出')
          return false
        }
      })
    },
    beforeImportOk () {
      setTimeout(_ => {
        this.exportLoading = false
      }, 500)
    },
    async beforeImportQuery () {
      console.log('this.formInline', this.formInline)
      var p = this.formInline.page
      var temp = { ...this.formInline }
      delete temp.page
      var query = {
        ...temp,
        ...p,
        size: 1000
      }
      console.log(query, temp, p)
      var itemAuditStatusMap = {
        0: '未上报',
        1: '待审核',
        2: '审核通过',
        3: '已退回'
      }

      if (query.dates && query.dates.length > 0) {
        query.startTime = query.dates[0]
        query.endTime = query.dates[1]
      }

      if (typeof query.areaId === 'object') {
        query.areaId = query.areaId[query.areaId.length - 1]
      }

      if (typeof query.categoryId === 'object') {
        query.categoryId = query.categoryId[query.categoryId.length - 1]
      }

      if (typeof query.productCategoryId === 'object') {
        query.productCategoryId = query.productCategoryId[query.productCategoryId.length - 1]
      }
      if (this.extraQuery.auditStatus) {
        query.type = this.formInline.type ? this.formInline.type : ''
        query.auditStatus = this.formInline.auditStatus ? [this.formInline.auditStatus] : []
      }

      var list = await this.query(query).then((res) => {
        console.log('res', res)
        var list = res.data.records.map((item) => {
          return {
            ...item,
            id: item.id || '',
            status: item.status ? '是' : '否',
            auditStatus: itemAuditStatusMap[item.auditStatus],
            createTime:
              this.$moment(item.createTime).format('YYYY-MM-DD HH:mm:ss') ||
              ''
          }
        })
        this.json_data = list
        return list
      })
      if (list.length === 0) {
        this.$message.warning('无数据可导出')
        return false
      }

      return list

      // this.$message.warning('无数据可导出')
    },
    query (params) {
      var mockData = {
        code: 200,
        data: {
          current: 1,
          records: [],
          pages: 1,
          size: 10,
          total: 0
        },
        msg: ''
      }
      return Promise.resolve(mockData)
    },
    init () {
      this.toPage(1)
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    importClick(){},
    add () {
    },
    onSubmit () {

    },
    edit (id) {

    },
    view (id) {

    },
    remove (id) {
      this.$confirm('请确认是否删除此数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

      })
    },
    ddd (val) {
      this.tableTile = val
      var json = {

      }

      this.tableTile.forEach(ele => {
        if (ele.prop) {
          json[ele.columnLabel] = ele.prop
        }
      })

      console.log('json', json)

      this.json_fields = json
    },
    checkCondition (item, condition) {
      var ok = true
      condition.forEach((ele) => {
        if (ele.value instanceof Array) {
          ok = false

          ele.value.forEach((ele2) => {
            if (item[ele.key] === ele2) {
              ok = true
            }
          })
        } else if (item[ele.key] !== ele.value) {
          ok = false
        }
      })
      return ok
    }
  }
})
</script>

<style lang="scss" scoped>
@import "@/assets/css/global-variable";
.g-role-list-box {
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 12px 20px;
}
</style>
